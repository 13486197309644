import { BaseService } from "./base";
import { UserService } from "./userService";
import { HatServices } from "./hat";
import { buildUrl } from "../utils/utility";

export const checkAuth = () => UserService.get("user/check_auth/", null, false);
export const removeAuth = () => UserService.get("user/remove_auth/");
// export const register = data => UserService.post("user/", data, false);
export const register = data => BaseService.post("users/", data, false);

export const login = data => UserService.post("user/login/", data, false);
export const updatePassword = data => UserService.patch("user/pp_password_change/", data);

export const passwordResetLink = (data) => UserService.post("user/send_reset_password_email/", data, false);
export const resetPassword = (data, token) => UserService.post("user/reset_password/", data, { headers: { token } });

export const listAreas = (id) => BaseService.get(`subdomains/${id}`);

export const listCategories = (areaId) =>
  BaseService.get(`categories/${areaId}`);
//create a master plan
export const createMasterplan = (data) =>
  BaseService.post("masterplan/select_areas_categories/", data);
//Select Areas and Categories
export const selectAreaAndCategories = (data, id) =>
  BaseService.post(`masterplan/select_areas_categories/${id}`, data);
//Prioritize Areas and Categories
export const prioritizeAreaAndCategories = (data, id) =>
  BaseService.post(`masterplan/prioritize_categories/${id}`, data);
//set measurement
export const setupMeasurement = (data, id) =>
  BaseService.post(`masterplan/setup_measurements/${id}`, data);
//set goals
export const setupGoals = (data, id) =>
  BaseService.post(`masterplan/setup_goals/${id}`, data);
//set Purpose
export const setupPurpose = (data, id) =>
  BaseService.post(`masterplan/setup_purpose/${id}`, data);
//set action-plan
export const setupActionPlan = (data, id) =>
  BaseService.post(`masterplan/setup_action_plan/${id}`, data);

// sign in as guest
export const guestSignin = () => BaseService.get("users/guest/", false);

//retrieve user data and last step journey
export const retrieveUserData = () => BaseService.get(`/users/my_user/`);
// export const retrieveUserData = userId => UserService.get(`user/${userId}/`);

export const updateTutorialProgress = (data, id) => BaseService.patch(`users/${id}/`, data);


//Update User Data + Last Step/Journey
export const updateLastStep = (formData, id) =>
  BaseService.patch(`masterplan/${id}/`, formData);

//retrieve masterplan details
export const retrieveMasterPlanDetails = (Id, query) =>
  BaseService.get(`masterplan/${Id}/${query}`);

//List masterplan
export const listMasterplan = () => BaseService.get("masterplan/");

//list domain
export const listDomain = (query) => BaseService.get(`domains/${query}`);

//create masterplan and select journey
export const createMasterplanSelectJourney = (data, query) =>
  BaseService.post(`masterplan/${query}`, data);

///Update use area
export const updateUserArea = (id, data) =>
  BaseService.patch(`user-areas/${id}/`, data);

// delete User Area
export const deleteUserArea = (id) => BaseService.remove(`user-areas/${id}/`);

//update goal
export const updateGoal = (id, data) => BaseService.patch(`goals/${id}/`, data);

//delete goal
export const deleteGoal = (id) => BaseService.remove(`goals/${id}/`);

//update action
export const updateActions = (id, data) =>
  BaseService.patch(`actions/${id}/`, data);

//delete action
export const deleteActions = (id, query) =>
  BaseService.remove(`actions/${id}/${query}`);
//create action

export const createActions = (data, query) =>
  BaseService.post(`actions/${query}`, data);

// get accountablity
export const getAccountability = (filterParams = {}) => {
  const url = buildUrl('accountability/', filterParams);
  return BaseService.get(url);
}

export const createAccountability = payload => BaseService.post(`accountability/`, payload);

export const deleteAccountability = payload => BaseService.remove(`accountability/${payload}/`);

//prioritize actions
export const prioritizeActionPlan = (id, data) =>
  BaseService.post(`masterplan/prioritize_actions/${id}`, data);

//generate area category
export const generateAreaCategories = (id) =>
  BaseService.get(`masterplan/generate_area_category/${id}`);
//Request OTP
export const requestOtp = (data) =>
  BaseService.post("users/request_otp/", data, false);

//VerifyOtp
export const verifyOtp = (data) =>
  BaseService.post("users/verify_otp/", data, false);

//update user data
// export const updateUserData = (data, id) => BaseService.patch(`users/${id}/`, data);
export const updateUserData = (data, id) => UserService.patch(`user/${id}/`, data);

export const deleteAccount = id => UserService.delete(`user/${id}/`);

// get instruction data
export const getInstructionData = (data, id) =>
  BaseService.get(`instructions/?domain=${data.domain}`);

// My Clients
export const getMyClients = () => BaseService.get(`users/my_clients/`);

//Get Progress
export const getProgress = (masterplan_id) =>
  BaseService.get(`masterplan/get_progress/?masterplan=${masterplan_id}`);

export const getActionsProgress = (goal_id) =>
  BaseService.get(`masterplan/get_progress_of_actions_on_goal/?goal=${goal_id}`);

export const getMissionStatement = (goalId) =>
  BaseService.get(`/mission-statements/?goal=${goalId}`);


export const getQuotes = () => HatServices.get(`quotes/`);

export const createMissionStatement = (data) =>
  BaseService.post(`/mission-statements/`, data);
export const updateMissionStatement = (data, id) =>
  BaseService.patch(`/mission-statements/${id}/`, data);

export const deleteRoles = id => BaseService.remove(`/roles/${id}/`);

export const deleteMissionStatement = id => BaseService.remove(`/mission-statements/${id}/`);

export const deleteValues = id => BaseService.remove(`/values/${id}/`);
// get predefined metrics data
export const getPredefinedMetrics = () => BaseService.get(`predefined-metrics/`);

export const getRoles = () => BaseService.get(`mission-statements/roles/?predefined=true&custom=true`);

export const createCustomRole = formData => BaseService.post(`/predefined-roles/`, formData);
export const deleteCustomRole = id => BaseService.remove(`/predefined-roles/${id}/`);

export const getValues = id => BaseService.get(`mission-statements/values/?role_id=${id}`);

export const createCustomValue = formData => BaseService.post(`/predefined-values/`, formData);
export const deleteCustomValue = id => BaseService.remove(`/predefined-values/${id}/`);

// get custom metrics data
export const getCustomMetrics = userId => BaseService.get(`custom-metrics/?user=${userId}`)

// create custom metric
export const createCustomMetric = data => BaseService.post(`custom-metrics/`, data);

// update custom metric
export const updateCustomMetric = (data, id) => BaseService.patch(`custom-metrics/${id}/`, data);

// delete custom metric
export const deleteCustomMetric = id => BaseService.remove(`custom-metrics/${id}/`);

// update custom unit
export const updateCustomUnit = (data, id) => BaseService.patch(`custom-metrics-units/${id}/`, data);

// delete custom unit
export const deleteCustomUnit = id => BaseService.remove(`custom-metrics-units/${id}/`);

export const bulkManageMetrics = payload => BaseService.post(`custom-metrics/bulk-manage/`, payload);

// Leverage Scores
export const getLeverageScores = id => BaseService.get(`/goals/list_available_areas/?goal=${id}`);
export const updateLeverageScores = payload => BaseService.post(`/goals/update_leverage_scores/`, payload);
